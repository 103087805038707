import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Layout, Menu } from 'antd';
import { BookOutlined, DashboardFilled, BookFilled, WalletFilled, FileTextOutlined, ShoppingCartOutlined, ShopOutlined, UserOutlined, SettingFilled, ToolOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
const { Sider } = Layout;

const SideBar = () => {
    const role = useSelector(state => state.auth.role);

    return (
        <Sider collapsible trigger={null} style={{backgroundColor: 'black', overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0}}>
            <div className="logo"></div>
            <Menu
                defaultSelectedKeys={['1']}
                mode="inline"
                theme="dark"
                style={{backgroundColor: 'black'}}
            >
                <Menu.Item key={1} icon={<DashboardFilled />}>
                    <span>Tablero</span>
                    <NavLink to={'/app'} />
                </Menu.Item>

                <Menu.Item key={25} icon={<BookFilled />}>
                    <span>Reportes</span>
                    <NavLink to={'/app/reports'}></NavLink>
                </Menu.Item>

                {/* {(role == null || role.p_reports_mi === 0) ? null :
                    <Menu.Item key={2} icon={<BookOutlined/>}>
                        <span>Reportes v2</span>
                        <NavLink to={'/app/repos'}/>
                    </Menu.Item>} */}

                {/* {(role == null || role.p_vouchers) ? null :
                    <Menu.Item key={4} icon={<FileTextOutlined/>}>
                        <span>Comprobantes</span>
                        <NavLink to={'/app/tickets'}/>
                    </Menu.Item>} */}

                { (role == null && role.p_currencies === 0 && role.p_vouchers === 0) ? null : <SubMenu key="sub2" title={<span><WalletFilled /><span>Pagos</span></span>}>
                        { (role.p_currencies === 0) ? null : <Menu.Item key="21"><NavLink to={'/app/payments/currencies'}>Tipos de cambio</NavLink></Menu.Item>}
                        { (role.p_vouchers === 0) ? null : <Menu.Item key="22"><NavLink to={'/app/vouchers'}>Comprobantes</NavLink></Menu.Item>}
                    </SubMenu> }
                { (role == null && role.p_inventories === 0) ? null : <SubMenu key="sub3" title={<span><ShoppingCartOutlined /><span>Inventarios</span></span>}>
                        { (role.p_items === 0) ? null : <Menu.Item key="31"><NavLink to={'/app/items'}>Artículos</NavLink></Menu.Item> }
                        { (role.p_locations === 0) ? null : <Menu.Item key="32"><NavLink to={'/app/items/locations'}>Ubicaciones</NavLink></Menu.Item> }
                        { (role.p_inventories === 0) ? null : <Menu.Item key="33"><NavLink to={'/app/inventories'}>Inventarios</NavLink></Menu.Item> }
                        { (role.p_products === 0) ? null : <Menu.Item key="34"><NavLink to={'/app/products'}>Productos</NavLink></Menu.Item> }
                    </SubMenu> }

                {/* {(inventoriesModule == null || inventoriesModule == 0 || true) ? null :
                    <SubMenu key={5} title={<span>Inventarios</span>}>
                        <Menu.Item key={51}><NavLink to={'/app/item-locations'}>Ubicaciones</NavLink></Menu.Item>
                        <Menu.Item key={52}><NavLink to={'/app/item'}>Artículos</NavLink></Menu.Item>
                        <Menu.Item key={53}><NavLink to={'/app/inventory'}>Inventarios</NavLink></Menu.Item>
                        <Menu.Item key={54}><NavLink to={'/app/product'}>Productos</NavLink></Menu.Item>
                    </SubMenu>} */}


                {(role == null || role.p_rooms === 0) ? null :
                    <SubMenu key={6} title={<span><ShopOutlined/><span>Habitaciones</span></span>}>
                        <Menu.Item key={61}><NavLink to={'/app/rooms'}>Habitaciones</NavLink></Menu.Item>
                        <Menu.Item key={62}><NavLink to={'/app/rent-types'}>Tipos de renta</NavLink></Menu.Item>
                    </SubMenu>}

                {(role == null || (role.p_usersad === 0 && role.p_usersop === 0)) ? null :
                    <Menu.Item key={7} icon={<UserOutlined/>}>
                        <span>Usuarios</span>
                        <NavLink to={'/app/users'}></NavLink>
                    </Menu.Item>}

                {(role == null || role.p_maintenance === 0) ? null :
                    <SubMenu key={9} title={<span><ToolOutlined/><span>Mantenimiento</span></span>}>
                        <Menu.Item key={91}><NavLink to={'/app/maintenance/reports'}>Reportes</NavLink></Menu.Item>
                        <Menu.Item key={92}><NavLink to={'/app/maintenance/rooms'}>Habitaciones</NavLink></Menu.Item>
                        <Menu.Item key={93}><NavLink to={'/app/maintenance/work-orders'}>Órdenes de trabajo</NavLink></Menu.Item>
                        {/* <Menu.Item key={94}><NavLink to={'/app/maintenance/plans'}>Planes</NavLink></Menu.Item> */}
                        <Menu.Item key={95}><NavLink to={'/app/maintenance/utilities'}>Servicios</NavLink></Menu.Item>
                    </SubMenu>}

                { (role == null && role.p_schedules === 0) ? null : <SubMenu key="sub8" title={<span><SettingFilled /><span>Ajustes</span></span>}>
                        { (role.p_schedules === 0) ? null : <Menu.Item key="81"><NavLink to={'/app/settings/schedules'}>Horario</NavLink></Menu.Item> }
                </SubMenu> }
            </Menu>
        </Sider>
    );
}

export default SideBar;