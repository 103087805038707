import { Auth, API } from 'aws-amplify';


class MaintenanceService {

    static async getUtility(hid, utility) {
        const date = new Date().toISOString().substring(0, 10);
        let url = '/maintenance/utilities/' + utility + '?hid=' + hid + '&timestamp=' + date;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async createUtilityEvent(hid, utility, data, userId, username) {
        let units = '';
        switch (utility) {
            case 'water':
                units = 'm3'
                break;
            case 'electricity':
                units = 'kWh';
                break;
            case 'gas':
                units = 'kg';
                break;
            default:
                break;
        }

        const newEvent = {
            utility: utility,
            reading: data,
            unit: units,
            userId: userId,
            username: username
        };

        return API.post('benackoAPI', '/maintenance/utilities?hid=' + hid, {body: newEvent})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRooms(hid) {
        const url = '/maintenance/rooms?hid=' + hid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRoom(rid) {
        const url = '/maintenance/rooms/' + rid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRoomFurnishings(rid) {
        const url = '/maintenance/rooms/' + rid + '/furnishings';

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRoomLogs(rid) {
        const url = '/maintenance/rooms/' + rid + '/logs?timestamp=2023-04-01';

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRoomFurnishing(rid, fid) {
        const url = '/maintenance/rooms/' + rid + '/furnishings/' + fid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async editRoomFurnishing(hid, rid, fid, key, value, userId, username) {
        const url = '/maintenance/rooms/' + rid + '/furnishings/' + fid + '?hid=' + hid;

        const body = {
            key: key,
            value: value,
            userId: userId,
            username: username
        };
        
        return API.put('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getRoomFurnishingLogs(rid, fid) {
        const url = '/maintenance/rooms/' + rid + '/furnishings/' + fid + '/logs?timestamp=2023-11-01';

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async createRoomFurnishing(hid, rid, name, model, invoiceNumber, serialNumber, description, itemType) {
        const url = '/maintenance/rooms/' + rid + '/furnishings?hid=' + hid;

        const body = {
            name: name,
            model: model,
            invoiceNumber: invoiceNumber,
            serialNumber: serialNumber,
            description: description,
            itemType: (itemType === 1) ? 'FURNITURE' : 'APPLIANCE'
        };
        
        return API.post('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getInventories(hid) {
        const url = '/maintenance/inventories?hid=' + hid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getInventory(iid) {
        const url = '/maintenance/inventories/' + iid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getInventoryItems(iid) {
        const url = '/maintenance/inventories/' + iid + '/items';

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getInventoryLogs(inventoryId) {
        var lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 2);

        var today = new Date();
        today.setDate(today.getDate() + 2);

        const todayStr = today.toISOString().substring(0, 10);
        const timestamp = lastMonth.toISOString().substring(0, 10);
        const url = '/maintenance/inventories/' + inventoryId + '/events?timestamp=' + timestamp + '&rangeTimestamp=' + todayStr;
        
        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async createInventory(hid, name) {
        const url = '/maintenance/inventories?hid=' + hid;

        const body = {
            name: name
        };
        
        return API.post('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async addInventoryItem(hid, inventoryId, name, minQuantity, stdQuantity, unit, userId, username, inventoryName) {
        const url = '/maintenance/inventories/' + inventoryId + '/items?hid=' + hid;

        const body = {
            name: name,
            minimumQuantity: minQuantity,
            standardQuantity: stdQuantity,
            itemUnit: unit,
            userId: userId,
            username: username,
            inventoryName: inventoryName
        };
        
        return API.post('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getInventoryItem(inventoryId, itemId) {
        const url = '/maintenance/inventories/' + inventoryId + '/items/' + itemId;
        
        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async editInventoryItem(hid, inventoryId, itemId, key, value, userId, username, event) {
        const url = '/maintenance/inventories/' + inventoryId + '/items/' + itemId + '?hid=' + hid;

        const body = {
            key: key,
            value: value,
            userId: userId,
            username: username,
            event: event
        };
        
        return API.put('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getInventoryItemLogs(inventoryId, itemId) {
        var lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 2);

        var today = new Date();
        today.setDate(today.getDate() + 2);

        const todayStr = today.toISOString().substring(0, 10);
        const timestamp = lastMonth.toISOString().substring(0, 10);
        const url = '/maintenance/inventories/' + inventoryId + '/items/' + itemId + '/events?timestamp=' + timestamp + '&rangeTimestamp=' + todayStr;
        
        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async deleteInventoryItem(hid, inventoryId, itemId, userId, username) {
        const url = '/maintenance/inventories/' + inventoryId + '/items/' + itemId + '?hid=' + hid;

        const body = {
            userId: userId,
            username: username
        };
        
        return API.del('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getWorkOrders(hid) {
        const url = '/maintenance/work-orders?hid=' + hid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async addWorkOrder(hid, name, roomId, roomName, notes, userId, username) {
        const url = '/maintenance/work-orders';

        const body = {
            hotelId: hid,
            name: name,
            roomId: roomId,
            roomName: roomName,
            notes: notes,
            userId: userId,
            username: username
        };
        
        return API.post('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getWorkOrder(wid) {
        const url = '/maintenance/work-orders/' + wid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getWorkOrderSupplies(wid) {
        const url = '/maintenance/work-orders/' + wid + '/supplies';

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async editWorkOrder(hid, wid, key, value, userId, username, event) {
        const url = '/maintenance/work-orders/' + wid + '?hid=' + hid;

        const body = {
            key: key,
            value: value,
            userId: userId,
            username: username,
            event: event
        };

        return API.put('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async addWorkOrderSupply(wid, id, name, unit, quantity) {
        const url = '/maintenance/work-orders/' + wid + '/supplies?';

        const body = {
            id: id,
            name: name,
            unit: unit,
            quantity: quantity
        };

        return API.post('benackoAPI', url, {body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getHotelFurnitureReport(hid) {
        const url = '/maintenance/reports/hotel-furniture?hid=' + hid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getUtilitiesByDateRange(hid, utility, fromDate, toDate) {
        let url = '/maintenance/utilities/' + utility + '?hid=' + hid + '&timestamp=' + fromDate + '&rangeTimestamp=' + toDate;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getWorkOrdersByDateRange(hid, fromDate, toDate) {
        let url = '/maintenance/reports/work-orders?hid=' + hid + '&timestamp=' + fromDate + '&rangeTimestamp=' + toDate;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getPlans(hid) {
        const url = '/maintenance/plans?hid=' + hid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getPlan(pid) {
        const url = '/maintenance/plans/' + pid;

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getPlanActivities(pid) {
        const url = '/maintenance/plans/' + pid + '/activities';

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }

    static async getPlanLogs(pid) {
        const url = '/maintenance/plans/' + pid + '/logs';

        return API.get('benackoAPI', url)
            .then(response => {
                if (response == null) {
                    throw Error('Petición incorrecta');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Petición incorrecta');});
    }
}

export default MaintenanceService;
