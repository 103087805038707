import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Table } from 'antd';

import MaintenanceService from '../../../../../services/maintenance';

import { errorNotification } from '../../../../../components/UI/Notifications/Notifications';


const RoomFurnishingsTab = ({roomId = null}) => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);

    const logsColumns = [
        {title: 'Nombre', dataIndex: 'name', defaultSortOrder: 'descend', sorter: (a,b) => a.name < b.name},
        {title: 'Tipo', dataIndex: 'itemType', render: (a) => (a === 'FURNITURE') ? 'Mueble' : 'Electrodoméstico'},
        {title: 'Modelo', dataIndex: 'model'},
        {title: 'Serie', dataIndex: 'serialNumber'},
        {title: 'Factura', dataIndex: 'invoiceNumber'},
        {title: 'Agregada', dataIndex: 'timestamp', render: (a) => new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()}
    ];

    useEffect(() => {
        if (roomId != null) {
            loadData();
        }
    }, [roomId]);


    const loadData = async () => {
        setLoading(true);

        await loadRoomLogs();

        setLoading(false);
    };

    const loadRoomLogs = async () => {
        await MaintenanceService.getRoomFurnishings(roomId)
            .then(res => {
                if (res != null) {
                    setLogs(res);
                }
            })
            .catch(err => errorNotification(err));
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%', marginTop: 30}}>
            <Col offset={1} span={22}>
                <Table
                    style={{cursor: 'pointer'}}
                    size="middle"
                    columns={logsColumns}
                    rowKey={'id'}
                    dataSource={logs}
                    scroll={{y: 440}}
                    locale={{
                        emptyText: 'No se encontraron datos',
                        cancelSort: 'Haz clic para cancelar ordenar',
                        triggerAsc: 'Haz clic para ordenar - ascendente',
                        triggerDesc: 'Haz clic para ordenar - descendente'
                    }}
                    onRow={ (record) => {
                        return {
                            onClick: () => {
                                history.push('/app/maintenance/rooms/' + roomId + '/furnishings/' + record.id);
                            }
                        };
                    }}
                    pagination={{
                        pageSize: 8,
                        showTotal: (total) => `Total: ${total}`
                    }}
                    loading={{
                        spinning: loading,
                        delay: 500,
                        size: 'large'
                    }}
                />
            </Col>
        </Row>
    );
};

export default RoomFurnishingsTab;