import { API, Auth } from 'aws-amplify';
import * as actionTypes from './actionTypes';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (idToken, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: idToken,
        userId: userId,
        isDataLoaded: false
    };
};

export const authLoad = (role, hotels, selectedHotel, companyId, user) => {
    return {
        type: actionTypes.AUTH_LOAD,
        role: role,
        hotels: hotels,
        selectedHotel: selectedHotel,
        companyId: companyId,
        user: user,
        isDataLoaded: true
    };
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    Auth.signOut()
        //.then( () => { })
        .catch( error => {
            console.log(error);
        });

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, expirationTime * 1000)
    };
};

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());

        Auth.signIn(email, password)
            .then( response => {

                API.get('benackoAPI', '/users/details?email=' + email, {
                    headers: {
                        Authorization: response.signInUserSession.idToken.jwtToken
                    }
                })
                .then( userData => {
                    const enabled = userData[0].enabled;

                    if (enabled) {
                        // API.put('benackoAPI', '/users/migration', {body: {email: email, pw: password}});
                        dispatch(authSuccess(response.signInUserSession.idToken.jwtToken, response.username));
                    } else {
                        console.log('User has been disabled');
                        dispatch(authFail(401));
                    }
                })
                .catch( disabledUser => {
                    console.log('Error loading user details:', disabledUser);
                    dispatch(authFail(401));
                });
            })
            .catch( error => {
                console.log(error);
                dispatch(authFail(error.code));
            });
    };
};

export const load = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {

            Auth.currentAuthenticatedUser()
                .then( user => {
                    const idToken = user.signInUserSession.idToken.jwtToken;
                    const email = user.attributes.email;

                    API.get('benackoAPI', '/users/details?username=' + email, {
                            headers: {
                                Authorization: idToken
                            }
                        })
                        .then(async userData => {
                            const roleId = userData[0].roleId;
                            const companyId = userData[0].companyId;
                            const userId = userData[0].id;
                            const user = userData[0];

                            let userRole = await API.get('benackoAPI', '/roles/' + roleId)
                                                .catch(err => {
                                                    console.log('Error loading role:', err);
                                                    reject();
                                                });
                            const processedRole = userRole[0];

                            let userHotels = await API.get('benackoAPI', '/users/hotels?userId=' + userId)
                                                .catch(err => {
                                                    console.log('Could not get user hotels', err);
                                                    reject();
                                                });
                            //console.log('userHotels', userHotels);

                            let allHotels = await API.get('benackoAPI', '/hotels')
                                                .catch(err => {
                                                    console.log('Could not get all hotels', err);
                                                    reject();
                                                });
                            //console.log('allHotels', allHotels);

                            var processedHotels = {};
                            var selectedHotel = 0;
                            userHotels.forEach(hotel => {
                                const hid = hotel.hotelId;
                                const selHotel = allHotels.find(ho => {return ho.id === hid});
                                const hotelName = selHotel?.name ?? null;

                                if (hotelName == null) { return; }

                                processedHotels[hid] = hotelName;

                                if (selectedHotel === 0) { selectedHotel = hid; }
                            });

                            //console.log('processed:', processedHotels);
                            
                            dispatch(authLoad(processedRole, processedHotels, selectedHotel, companyId, user));
                            resolve();


                            /*await API.get('benackoAPI', '/users/hotels?userId=' + userId, {
                                    headers: {
                                        Authorization: idToken
                                    }
                                })
                                .then(async userHotels => {
                                    var hotels = {};
                                    var selectedHotel = 0;

                                    await userHotels.forEach(async hotel => {
                                        await API.get('benackoAPI', '/hotels/' + hotel.hotelId, {
                                                headers: {
                                                    Authorization: idToken
                                                }
                                            })
                                            .then(hotelRes => {
                                                const hotelName = hotelRes[0].name;

                                                hotels[hotel.hotelId] = hotelName;
                                            });

                                        selectedHotel = hotel.hotelId;
                                    });

                                    API.get('benackoAPI', '/roles/' + roleId, {
                                            headers: {
                                                Authorization: idToken
                                            }
                                        })
                                        .then( roleRes => {
                                            const role = roleRes[0];
                                            dispatch(authLoad(role, hotels, selectedHotel, companyId, user));
                                            resolve();
                                        })
                                        .catch( errRole => {
                                            console.log('Error loading role:', errRole);
                                        });
                                })
                                .catch(userHotelsErr => {
                                    console.log('Error loading user hotels:', userHotelsErr);
                                    reject();
                                });
                            })*/
                        })
                        .catch(errGet => {
                            console.log('Error loading user data: ', errGet);
                            reject();
                        });
                })
                .catch(err => {
                    console.log(err);
                    reject();
                });
        });
    };
};

export const authCheckState = () => {
    return dispatch => {
        
        Auth.currentAuthenticatedUser()
            .then( response => {
                dispatch(authSuccess(response.signInUserSession.idToken.jwtToken, response.username));
            })
            .catch( () => {
                dispatch(logout());
            });
    };
};
