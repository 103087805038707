import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Input, Row, Skeleton } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import MaintenanceService from '../../../../../services/maintenance';

import { errorNotification, successNotification } from '../../../../../components/UI/Notifications/Notifications';

const FurnishingDetailsTab = ({roomId = null, furnishingId = null}) => {
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const userId = useSelector(state => state.auth.user?.id ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [furnishing, setFurnishing] = useState({});
    const [newName, setNewName] = useState('');
    const [newModel, setNewModel] = useState([]);
    const [newDescription, setNewDescription] = useState('');
    const [newInvoiceNumber, setNewInvoiceNumber] = useState([]);
    const [newSerialNumber, setNewSerialNumber] = useState([]);

    useEffect(() => {
        if (roomId != null && roomId > 0 && furnishingId != null) {
            loadData();
        }
    }, [roomId, furnishingId]);

    const loadData = async () => {
        setLoading(true);

        await loadFurnishing();

        setLoading(false);
    };

    const loadFurnishing = async () => {
        await MaintenanceService.getRoomFurnishing(roomId, furnishingId)
            .then(res => {
                if (res != null) {
                    setFurnishing(res);
                }
            })
            .catch(err => errorNotification(err));
    };

    const validateName = () => {
        if (submitted || newName == null || newName.length <= 0 || furnishing.name === newName) {
                return true;
        } else {
            return false;
        }
    };

    const validateModel = () => {
        if (submitted || newModel == null || newModel.length <= 0 || furnishing.model === newModel) {
                return true;
        } else {
            return false;
        }
    };

    const validateDescription = () => {
        if (submitted || newDescription == null || newDescription.length <= 0 || furnishing.description === newDescription) {
                return true;
        } else {
            return false;
        }
    };

    const validateInvoiceNumber = () => {
        if (submitted || newInvoiceNumber == null || newInvoiceNumber.length <= 0 || furnishing.invoiceNumber === newInvoiceNumber) {
                return true;
        } else {
            return false;
        }
    };

    const validateSerialNumber = () => {
        if (submitted || newSerialNumber == null || newSerialNumber.length <= 0 || furnishing.serialNumber === newSerialNumber) {
                return true;
        } else {
            return false;
        }
    };

    const editFurnishing = (property, currentValue, newValue) => {
        setLoading(true);
        
        if (!submitted) {
            setSubmitted(true);

            if (newValue != null && newValue.length != 0 && newValue != currentValue) {
                MaintenanceService.editRoomFurnishing(hid, roomId, furnishingId, property, newValue, userId, username)
                    .then(() => successNotification('El parámetro ha sido editado exitosamente'))
                    .catch(err => errorNotification(err))
                    .finally(() => {
                        loadData();
                        setSubmitted(false);
                    });
            }
        }
    };

    return (
        <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Nombre</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={furnishing?.name ?? ''} onChange={(e) => setNewName(e.target.value)} disabled={submitted}/></span>
                        <span style={{marginLeft: 10, width: 20}}>
                            <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                onClick={() => editFurnishing('name', furnishing.name, newName)} hidden={validateName()} disabled={validateName()}
                            /></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Modelo</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={furnishing?.model ?? ''} onChange={(e) => setNewModel(e.target.value)} disabled={submitted}/></span>
                        <span style={{marginLeft: 10, width: 20}}>
                            <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                onClick={() => editFurnishing('model', furnishing.model, newModel)} hidden={validateModel()} disabled={validateModel()}
                            /></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Descripción</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={furnishing?.description ?? ''} onChange={(e) => setNewDescription(e.target.value)} disabled={submitted}/></span>
                        <span style={{marginLeft: 10, width: 20}}>
                            <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                onClick={() => editFurnishing('description', furnishing.description, newDescription)} hidden={validateDescription()} disabled={validateDescription()}
                            /></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Número de factura</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={furnishing?.invoiceNumber ?? ''} onChange={(e) => setNewInvoiceNumber(e.target.value)} disabled={submitted}/></span>
                        <span style={{marginLeft: 10, width: 20}}>
                            <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                onClick={() => editFurnishing('invoiceNumber', furnishing.invoiceNumber, newInvoiceNumber)} hidden={validateInvoiceNumber()} disabled={validateInvoiceNumber()}
                            /></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Número de serie</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={furnishing?.serialNumber ?? ''} onChange={(e) => setNewSerialNumber(e.target.value)} disabled={submitted}/></span>
                        <span style={{marginLeft: 10, width: 20}}>
                            <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                onClick={() => editFurnishing('serialNumber', furnishing.serialNumber, newSerialNumber)} hidden={validateSerialNumber()} disabled={validateSerialNumber()}
                            /></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Tipo de mobiliario</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={(furnishing?.itemType === 'FURNITURE' ? 'Mueble' : 'Electrodoméstico') ?? ''} disabled={true}/></span>
                    </Col>
                </Row>
            </Row>
        </Skeleton>
    );
};

export default FurnishingDetailsTab;