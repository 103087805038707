import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Col, Row, Space, Tabs } from 'antd';

import MaintenanceService from '../../../../services/maintenance';

import { errorNotification } from '../../../../components/UI/Notifications/Notifications';
import PlanActivitiesTab from './PlanActivitiesTab/PlanActivitiesTab';
import PlanLogsTab from './PlanLogsTab/PlanLogsTab';


const TabPane = Tabs.TabPane;

const PlanDetails = () => {
    const {planId} = useParams();
    
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState({});

    useEffect(() => {
        if (planId != null) {
            loadData();
        }
    }, [planId]);

    const loadData = async () => {
        setLoading(true);

        await loadPlan();

        setLoading(false);
    };

    const loadPlan = async () => {
        await MaintenanceService.getPlan(planId)
            .then(res => {
                if (res != null) {
                    setPlan(res);
                }
            })
            .catch(err => errorNotification(err));
    };

    const goToAdd = () => {
        history.push('/app/maintenance/plans/' + planId + '/activity-add');
    };


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                <Col span={22}>
                    <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <NavLink to={'/app/maintenance/plans'}>{'Planes de mantenimiento'}</NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {'Detalle de plan de mantenimiento'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Col>
                <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <Button type='primary' loading={loading} onClick={() => goToAdd()}>Agregar atividad</Button>
                    </Space>
                </Col>
            </Row>

            <Row style={{display: 'flex', alignItems: 'center', width: '100%', height: 60}}>
                <span style={{marginLeft: 0, fontSize: 20}}>{plan?.name ?? ''}</span>
            </Row>

            <Tabs defaultActiveKey={1}>
                <TabPane tab="Actividades" key="1">
                    <PlanActivitiesTab/>
                </TabPane>
                {/* <TabPane tab="Bitácora" key="2">
                    <PlanLogsTab/>
                </TabPane> */}
            </Tabs>
        </Row>
    );
}

export default PlanDetails;