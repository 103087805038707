import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Utilities from './Utilities/Utilities';
import CreateUtilityEvent from './Utilities/CreateUtilityEvent/CreateUtilityEvent';
import UtilityDetails from './Utilities/UtilityDetails/UtilityDetails';

import Rooms from './Rooms/Rooms';
import RoomDetails from './Rooms/RoomDetails/RoomDetails';
import RoomFurnishing from './Rooms/RoomFurnishing/RoomFurnishing';
import CreateRoomFurnishing from './Rooms/RoomFurnishing/CreateRoomFurnishing/CreateRoomFurnishing';

import WorkOrders from './WorkOrders/WorkOrders';
import AddWorkOrder from './WorkOrders/AddWorkOrder/AddWorkOrder';
import WorkOrderDetails from './WorkOrders/WorkOrderDetails/WorkOrderDetails';
import AddSupplies from './WorkOrders/AddSupplies/AddSupplies';

import Plans from './Plans/Plans';
import PlanDetails from './Plans/PlanDetails/PlanDetails';

import Reports from './Reports/Reports';
import RoomFurnitureHotel from './Reports/RoomFurnitureHotel/RoomFurnitureHotel';
import WorkOrderReportSelector from './Reports/WorkOrdersReport/WorkOrderReportSelector';
import WorkOrdersReport from './Reports/WorkOrdersReport/WorkOrdersReport';
import UtilitiesReportSelector from './Reports/UtilitiesReport/UtilitiesReportSelector';
import UtilitiesReport from './Reports/UtilitiesReport/UtilitiesReport';

const Maintenance = () => {
    return (
        <Switch>
            <Route path="/app/maintenance"
                render={({ match: { url } }) => (
                    <div>
                        <Route exact path={`${url}/reports`} component={Reports} />
                        <Route exact path={`${url}/reports/hotel-furniture`} component={RoomFurnitureHotel} />
                        <Route exact path={`${url}/reports/work-orders-selector`} component={WorkOrderReportSelector} />
                        <Route exact path={`${url}/reports/work-orders`} component={WorkOrdersReport} />
                        <Route exact path={`${url}/reports/utilities-selector`} component={UtilitiesReportSelector} />
                        <Route exact path={`${url}/reports/utilities`} component={UtilitiesReport} />

                        <Route exact path={`${url}/utilities`} component={Utilities} />
                        <Route exact path={`${url}/utilities/create`} component={CreateUtilityEvent} />
                        <Route exact path={`${url}/utilities/details`} component={UtilityDetails} />
                        
                        <Route exact path={`${url}/rooms`} component={Rooms} />
                        <Route exact path={`${url}/rooms/:roomId`} component={RoomDetails} />
                        <Route exact path={`${url}/rooms/:roomId/furnishings/:furnishingId`} component={RoomFurnishing} />
                        <Route exact path={`${url}/rooms/:roomId/add/furnishing`} component={CreateRoomFurnishing} />

                        <Route exact path={`${url}/work-orders`} component={WorkOrders} />
                        <Route exact path={`${url}/work-orders-add`} component={AddWorkOrder} />
                        <Route exact path={`${url}/work-orders/:workOrderId`} component={WorkOrderDetails} />
                        <Route exact path={`${url}/work-orders/:workOrderId/supply-add`} component={AddSupplies} />

                        {/* <Route exact path={`${url}/plans`} component={Plans} />
                        <Route exact path={`${url}/plans/:planId`} component={PlanDetails} /> */}
                    </div>
                )}
            />
        </Switch>
    );
}

export default Maintenance;