import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Input, Row, Skeleton } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import MaintenanceService from '../../../../../services/maintenance';
import { errorNotification, successNotification } from '../../../../../components/UI/Notifications/Notifications';


const WorkOrderDetailsTab = () => {
    const {workOrderId} = useParams();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const userId = useSelector(state => state.auth.user?.id ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');

    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [workOrder, setWorkOrder] = useState(null);
    const [newNotes, setNewNotes] = useState(null);

    useEffect(() => {
        if (workOrderId != null) {
            loadData();
        }
    }, [workOrderId]);

    const loadData = async () => {
        await MaintenanceService.getWorkOrder(workOrderId)
            .then(res => {
                if (res != null) {
                    setWorkOrder(res);
                    setNewNotes(res?.notes ?? '');
                }
            })
            .catch(err => errorNotification(err))
            .finally(() => setLoading(false));
    };
    
    const validateNotes = () => {
        if (submitted || newNotes == null || workOrder?.notes === newNotes) {
                return true;
        } else {
            return false;
        }
    };

    const renderTimestamp = (a, u) => {
        if (u == null || u === 0 || a == null) { return ''; }

        return new Date(a[0], (a[1] - 1), a[2], a[3], a[4], a[5]).toLocaleString();
    };


    const editItem = (property, currentValue, newValue) => {
        setLoading(true);
        
        if (!submitted) {
            setSubmitted(true);

            if (newValue != null && newValue != currentValue) {
                MaintenanceService.editWorkOrder(hid, workOrderId, property, newValue, userId, username, 'EDIT-WORK_ORDER')
                    .then(() => successNotification('El valor ha sido editado exitosamente'))
                    .catch(err => errorNotification(err))
                    .finally(() => {
                        loadData();
                        setSubmitted(false);
                    });
            }
        }
    };

    return (
        <Skeleton loading={loading} paragraph={{rows: 9, cols: 1}} active>
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                {
                    workOrder?.roomId != 0 &&
                    <Row style={{width: '100%', height: 60, marginBottom: 40}}>
                        <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Habitación</span></Col>
                        <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                            <Input type="text" maxLength={200} defaultValue={workOrder?.roomName ?? ''} disabled={true}/></span>
                        </Col>
                    </Row>
                }

                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Notas</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input.TextArea style={{width: 400}} min={0} step={1} defaultValue={workOrder?.notes ?? ''} onChange={(e) => setNewNotes(e.target.value)} disabled={workOrder?.validationUserId != 0 || submitted}/></span>
                        <span style={{marginLeft: 10, width: 20}}>
                            <Button type="danger" shape="circle" size="small" style={{marginLeft: 10}} icon={<SaveOutlined />}
                                onClick={() => editItem('notes', workOrder?.notes, newNotes)} hidden={validateNotes()} disabled={validateNotes()}
                            /></span>
                    </Col>
                </Row>

                <Row style={{width: '100%', height: 60, marginTop: 40}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Usuario de apertura</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={workOrder?.openUsername ?? ''} disabled={true}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Apertura</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={renderTimestamp(workOrder?.openTimestamp, workOrder?.openUserId)} disabled={true}/></span>
                    </Col>
                </Row>

                <Row style={{width: '100%', height: 60, marginTop: 40}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Usuario de cierre</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={workOrder?.closeUsername ?? ''} disabled={true}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Cierre</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={renderTimestamp(workOrder?.closeTimestamp, workOrder?.closeUserId)} disabled={true}/></span>
                    </Col>
                </Row>

                <Row style={{width: '100%', height: 60, marginTop: 40}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Usuario de validación</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={workOrder?.validationUsername ?? ''} disabled={true}/></span>
                    </Col>
                </Row>
                <Row style={{width: '100%', height: 60}}>
                    <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 40, fontWeight: 'bold'}}>Apertura</span></Col>
                    <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}><span style={{marginLeft: 20, width: 400}}>
                        <Input type="text" maxLength={200} defaultValue={renderTimestamp(workOrder?.validationTimestamp, workOrder?.validationUserId)} disabled={true}/></span>
                    </Col>
                </Row>
            </Row>
        </Skeleton>
    );
};

export default WorkOrderDetailsTab;