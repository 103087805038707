import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Input, Row, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { disableHotelSelector, enableHotelSelector } from '../../../../store/actions';
import MaintenanceService from '../../../../services/maintenance';
import { errorNotification } from '../../../../components/UI/Notifications/Notifications';


const RoomFurnitureHotel = () => {
    const dispatch = useDispatch();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [rooms, setRooms] = useState([]);
    const [furniture, setFurniture] = useState([]);
    const [filteredFurniture, setFilteredFurniture] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const furnitureColumns = [
        {title: 'Habitación', dataIndex: 'room', defaultSortOrder: 'ascend', sorter: (a,b) => a.Room < b.Room},
        {title: 'Nombre', dataIndex: 'name'},
        {title: 'Tipo', dataIndex: 'itemType', render: (a) => (a === 'FURNITURE') ? 'Mueble' : 'Electrodoméstico'},
        {title: 'Modelo', dataIndex: 'model'},
        {title: 'Serie', dataIndex: 'serialNumber'},
        {title: 'Factura', dataIndex: 'invoiceNumber'},
        {title: 'Agregada', dataIndex: 'timestamp', render: (a) => new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()}
    ];

    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    useEffect(async () => {
        if (hid != null && hid != 0) {
            setLoading(true);
            await loadRooms();
            await loadFurniture();
            setLoading(false);
        }
    }, [hid]);

    useEffect(() => {
        if (inputValue !== null && inputValue !== undefined && inputValue !== '') {
            const newFurniture = inputValue ? furniture.filter(i => (i.name.toLowerCase().indexOf(inputValue) > -1)) : furniture;
            setFilteredFurniture(newFurniture);
        } else {
            setFilteredFurniture(furniture);
        }
    }, [inputValue]);

    useEffect(() => {
        if (furniture != null && furniture.length > 0) {
            clearCurrentFurniture();
            setFilteredFurniture(furniture);
        }
    }, [furniture]);

    const loadRooms = async () => {
        await MaintenanceService.getRooms(hid)
            .then(res => {
                if (res != null) {
                    setRooms(res);
                }
            })
            .catch(err => errorNotification(err));
    };

    const loadFurniture = async () => {
        await MaintenanceService.getHotelFurnitureReport(hid)
            .then(res => {
                if (res != null) {

                    const processedFurniture = [];
                    res.forEach(furn => {
                        const pk = furn.pk.split('#');
                        const pkRid = parseInt(pk[2] ?? 0);
                        const room = rooms.find(r => {return r.id === pkRid;});

                        furn['room'] = room.name ?? '-';
                        processedFurniture.push(furn);
                    });

                    setFurniture(processedFurniture);
                }
            })
            .catch(err => errorNotification(err));
    };

    const clearCurrentFurniture = () => {
        setFilteredFurniture([]);
        setInputValue('');
    };

    const onChangeText = (text) => {
        setInputValue(text);
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 40}}>
                <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Input
                        size="middle"
                        prefix={<SearchOutlined />}
                        placeholder={'Buscar'}
                        allowClear={true}
                        value={inputValue}
                        onChange={(ev) => onChangeText(ev.target.value)}
                    />
                </Col>
            </Row>

            <Row style={{width: '100%', height: '100%', flexGrow: 1, marginBottom: 20}}>
                <Col span={24}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size={'small'}
                        bordered={true}
                        loading={loading}
                        columns={furnitureColumns}
                        dataSource={filteredFurniture}
                        pagination={false}
                        rowKey={'id'}
                        scroll={{y: '100%'}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar la ordenación',
                            triggerAsc: 'Haz clic para ordenar ascendentemente',
                            triggerDesc: 'Haz clic para ordenar descendentemente'
                        }}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default RoomFurnitureHotel;